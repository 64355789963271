
import user from "@/stores/user";
import {createStore} from "vuex";

const store = createStore({
  state: {
    // 定义状态
  },
  mutations: {
    // 定义mutations
  },
  actions: {
    // 定义actions
  },
  modules: {
    // 如果有模块的话，在这里导入
    user,
  }
});

export default store

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from "@/stores/index";


/*const store = createStore({
    state: {
        // 定义状态
    },
    mutations: {
        // 定义mutations
    },
    actions: {
        // 定义actions
    },
    modules: {
        // 如果有模块的话，在这里导入
    }
});*/

const app = createApp(App)
app.use(router)
// 使用store
app.use(store);
app.mount('#app')

// // index.js

import { createRouter, createWebHistory } from 'vue-router'
// import HelloWorld from "@/components/HelloWorld.vue";
// import SignUp from "@/components/SignUp.vue";

const routerHistory = createWebHistory()

const routes=[
    // {
    //     path: '/', // 默认路径(会自动跳转)
    //     redirect: {name: 'HelloWorld'} // 根据路由名重定向
    //     // redirect: {name: 'ApplicationOne'} // 根据路由名重定向
    // },
    {name:'HelloWorld',path: '/',component: ()=>import("@/components/HelloWorld.vue")},
    // {name:'SignUp',path: '/',component: ()=>import("@/components/SignUp.vue")},
    {
        path: "/signUp",component: () => import("@/components/SignUp.vue")
    },
    {
        path: "/ApplicationList",component: () => import("../components/ApplicationList.vue")
    },
    {
        path: "/ApplicationSuccess",component: () => import("../components/ApplicationSuccess.vue")
    },
    {
        path: "/ApplicationFail",component: () => import("../components/ApplicationFail.vue")
    },
    {
        path: "/OrderDetail",component: () => import("../components/OrderDetail.vue")
    }

]
const router = createRouter({
  history: routerHistory,
  routes
})

export default router

import axios from 'axios'
import {getToken} from "@/network/auth";
// import store from "@/stores";
import cache from "@/network/cache";
// import errorCode from "@/network/errorCode";
import {tansParams} from "@/network/ruoyi";
window.api = process.env.VUE_APP_BASE_API

export default (() => {

  const ax = axios.create({
    // baseURL: window.api,
    // baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: 'http://localhost:8080',
    baseURL: 'https://gd.lanwei.group',
    // baseURL: 'http://192.168.0.3:7091/api',
    // baseURL: 'http://39.107.172.25:81/api',
    // 跨域时候允许携带凭证
    // withCredentials: true,
    timeout: 60000 // 网络请求超时毫秒
  })
    ax.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

  ax.interceptors.request.use(config => {
      const token = getToken();
      // 是否需要防止数据重复提交
      const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + getToken()
      }
      if (config.method === 'get' && config.params) {
          let url = config.url + '?' + tansParams(config.params);
          url = url.slice(0, -1);
          config.params = {};
          config.url = url;
      }
      if ( !isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
          const requestObj = {
              url: config.url,
              data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
              time: new Date().getTime()
          }
          const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
          const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
          if (requestSize >= limitSize) {
              console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
              return config;
          }
          const sessionObj = cache.session.getJSON('sessionObj')
          if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
              cache.session.setJSON('sessionObj', requestObj)
          } else {
              const s_url = sessionObj.url;                  // 请求地址
              const s_data = sessionObj.data;                // 请求数据
              const s_time = sessionObj.time;                // 请求时间
              const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
              if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                  const message = '数据正在处理，请勿重复提交';
                  console.warn(`[${s_url}]: ` + message)
                  return Promise.reject(new Error(message))
              } else {
                  cache.session.setJSON('sessionObj', requestObj)
              }
          }
      }
    return config
  }, error => {
    console.log(error)
    Promise.reject(error)
  })

// 响应拦截器
  ax.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        // const code = res.data.code || 200;
        // 获取错误信息
        // const msg = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        // if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
        //   return res.data
        // }
        // return res;
      if (res.status !== 200) throw new Error(res)
      else {
          return res.data
      }
      },
      error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
          message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
          message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
          message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        return Promise.reject(error)
      }
  )


  return function (config) {
    return ax(config)
  }
})()
